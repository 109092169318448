.lista {
  /* height: 50px; */
  width: 100%;
}

.controls-section {
  display: flex;
}






